import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import Listing from "../../components/Listing"
import Seo from "../../components/Seo"

export default function Topics({ data }) {
  //Data destructuring
  const topicsData = data.topics.edges.map(edge => edge.node)
  //SEO
  const seoTitle = { ru: "Темы", fi: "Aiheet" }
  const seoSlug = { current: "aiheet" }

  return (
    <Layout>
      <Seo title={seoTitle} slug={seoSlug} />
      {/* Listing */}
      <section className="section-header">
        <p className="section-caption">
          <span className="russian" lang="ru">
            Темы
          </span>
          <span className="finnish" lang="fi">
            Aiheet
          </span>
        </p>
      </section>
      <Listing data={topicsData} />
    </Layout>
  )
}

export const query = graphql`
  query GetTopics {
    topics: allSanityTopic(sort: { fields: relevance, order: DESC }) {
      edges {
        node {
          _id
          _key
          _type
          covers {
            format
            image {
              asset {
                _id
              }
            }
          }
          description {
            fi
            ru
          }
          title {
            ru
            fi
          }
          slug {
            current
          }
        }
      }
    }
  }
`
