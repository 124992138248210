import React, { useState, useEffect } from "react"
import ListingCard from "./ListingCard"

export default function Listing({ data, limit, mode, pagesPerLoad }) {
  const listingMode = mode
    ? `listing-cards-container ${mode}`
    : "listing-cards-container"
  const ppl = pagesPerLoad ? pagesPerLoad : data.length

  // State of trimmed list without scheduled articles
  const [trimmedList, setTrimmedList] = useState([])

  // State for the list
  const [shownList, setShownList] = useState([])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(false)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = shownList.length
      const isMore = currentLength < trimmedList.length
      const nextResults = isMore
        ? trimmedList.slice(currentLength, currentLength + ppl)
        : []
      setShownList([...shownList, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore, trimmedList]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = shownList.length < trimmedList.length
    setHasMore(isMore)
  }, [shownList, trimmedList]) //eslint-disable-line

  //After trimmed list is set, set shown list and if it has more to show
  useEffect(() => {
    setShownList(trimmedList.slice(0, ppl))
    setHasMore(trimmedList.length > ppl)
  }, [trimmedList]) //eslint-disable-line

  //Initial load of the data in trimmed list
  useEffect(() => {
    const withoutScheduled = data.filter(
      article => !article.date || new Date(article.date) <= new Date()
    )
    setTrimmedList(
      limit ? withoutScheduled.slice(0, limit[0]) : withoutScheduled
    )
  }, [data]) //eslint-disable-line

  return (
    <>
      <div className={listingMode}>
        {shownList.map((article, i) => {
          return <ListingCard data={article} mode={mode} key={i} />
        })}
      </div>
      {pagesPerLoad && hasMore && (
        <button onClick={handleLoadMore} className="load-more">
          <span>Загрузить еще</span>
          <span>Lataa lisää</span>
        </button>
      )}
    </>
  )
}
