import React from "react"
import { Link } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import { nonBreakable } from "../constants/helpers"

export default function ListingCard({ data, mode }) {
  //Common
  const titleRu = data.title?.ru ? nonBreakable(data.title.ru) : ""
  const titleFi = (data.title?.fi !== data.title?.ru) ? nonBreakable(data.title.fi) : ""
  const descRu = (data.description && data.description.ru) ? nonBreakable(data.description.ru) : ""
  const descFi = (data.description && data.description.fi) ? nonBreakable(data.description.fi) : ""
  let slug = ""
  let author = ""
  let sectionRu = ""
  let sectionFi = ""
  let asset = null
  const alt = `${titleRu} / ${titleFi}`
  const imageWidth = mode === "featured" ? 960 : 480
  const imageHeight = mode === "featured" ? 640 : 320
  //Article
  if (data._type === "article") {
    author = (data.author && mode!=="sections") && data.author.name.fi
    sectionRu = data.section && data.section.title.ru
    sectionFi = data.section && data.section.title.fi
    slug = `/teksti/${data.slug.current}/`
    asset = data.cover && data.cover.asset
    asset = data.thumb ? data.thumb.asset : asset
  }
  //Topic
  if (data._type === "topic") {
    slug = `/${data.slug.current}`
    if (data.covers.length > 0) {
      for (const cover of data.covers) {
        if ((mode === "featured" && cover.format === "landscape-animated") || (!mode && cover.format === "landscape")) {
          asset = cover.image && cover.image.asset
          break
        } else if (mode === "featured" && cover.format === "landscape") {
          asset = cover.image && cover.image.asset
        }
      }
    }
  }
  //Styles
  let cardType = `listing-card ${data._type} `
  mode && (cardType += mode)

  return (
    <div className={cardType}>
      {asset && <Link to={slug}><SanityImage asset={asset} alt={alt} width={imageWidth} height={imageHeight} className="card-thumb" /></Link> }
      <div className="card-content">
        {author && <span className="card-author name-caption">{author}</span>}
        {mode === "sections" && (
          <p className="card-section section-caption">
            <span className="russian">{sectionRu}</span>
            <span className="finnish">{sectionFi}</span>
          </p>
          
        )}
        <Link to={slug}>
          <h3 className="listing-card-title">
            <span className="russian" lang="ru">{titleRu}</span>
            <span className="finnish" lang="fi">{titleFi}</span>
          </h3>
        </Link>
        {(descRu || descFi) && (
          <p className="card-desc block-small">
            {descRu && <span className="russian" lang="ru">{descRu}</span>}
            {descFi && <span className="finnish" lang="fi">{descFi}</span>}
          </p>
        )}
        {(mode === "featured") && <Link to={slug}className="issue-link">Читать / Lue lisää</Link>}
      </div>
    </div>
  )
}
